import { Box, Button, Stack, Typography } from "@mui/material";
import { PageBlocked } from "../svgs/page-blocked";
import { useRouter } from "../hooks/use-router";
import { paths } from "../paths";

export const MobileBlockerPage = ({ show }: { show: boolean }) => {
  const { push } = useRouter();
  return (
    <Box
      sx={{
        display: show ? "block" : "none",
      }}
      data-cy="mobile-blocker"
      mt={10}
      px={5}
    >
      <Stack justifyContent="center" alignItems="center" spacing={2}>
        <PageBlocked sx={{ width: "30%", height: "auto", maxWidth: "200px" }} />
        <Typography variant="h5" align="center" pt={4}>
          This view does not work on lower resolutions.
        </Typography>
        <Typography color="GrayText" align="center">
          For optimised viewing and access to all features, please view at a
          higher resolution.
        </Typography>
        <Button variant="contained" onClick={() => push(paths.accountOverview)}>
          Back to account
        </Button>
      </Stack>
    </Box>
  );
};
