import {
  Document,
  useAceAccountManagement,
  openFileBlob,
} from "@archax/web-lib";
import { Fragment, useEffect, useState } from "react";
import {
  Box,
  Card,
  Container,
  Stack,
  Typography,
  Tabs,
  Tab,
  TextField,
  Button,
  Skeleton,
  Divider,
  InputAdornment,
  CircularProgress,
  Chip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { CoolOffBanner } from "../../components/account-management/cool-off-banner";
import { getDocuments } from "../../services/get-documents";
import { KYC_STATUS } from "../../constants";
import { format } from "date-fns";

const TABS_FILTERS = ["bulletin", "policy"];

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  return new Intl.DateTimeFormat("en-GB", options).format(date);
};

export const DocumentLibrary = () => {
  const { currentToken, profileData, kycData } = useAceAccountManagement();
  const [loadingDocuments, setLoadingDocuments] = useState(true);
  const [error, setError] = useState(false);
  const [currentTab, setTab] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [documents, setDocuments] = useState<Document[] | null>(null);
  const [loadingDocument, setLoadingDocument] = useState<string | null>(null);

  const loadDocuments = async () => {
    setLoadingDocuments(true);
    try {
      const documents = await getDocuments(currentToken);
      setDocuments(documents);
    } catch (e) {
      setError(true);
    }

    setLoadingDocuments(false);
  };

  const handleOpenDocument = async (uri: string, docKey: string) => {
    setLoadingDocument(docKey);
    try {
      await openFileBlob(currentToken, uri);
    } finally {
      setLoadingDocument(null);
    }
  };

  useEffect(() => {
    loadDocuments();
  }, []);

  const groupedDocuments = documents
    ? documents
        .filter((doc) => doc.category === TABS_FILTERS[currentTab])
        .filter(
          (doc) =>
            doc.label
              .toLocaleLowerCase()
              .includes(searchValue.toLocaleLowerCase()) ||
            doc.description
              .toLocaleLowerCase()
              .includes(searchValue.toLocaleLowerCase()) ||
            formatDate(doc.updated)
              .toLocaleLowerCase()
              .includes(searchValue.toLocaleLowerCase()),
        )
        .sort(
          (a, b) =>
            new Date(b.updated).getTime() - new Date(a.updated).getTime(),
        )
        .reduce((groups: { [key: string]: Document[] }, doc: Document) => {
          const key = format(new Date(doc.updated), "MMMM yyyy");
          if (!groups[key]) {
            groups[key] = [];
          }
          groups[key].push(doc);
          return groups;
        }, {})
    : {};

  const documentLinks = Object.entries(groupedDocuments).map(
    ([month, docs]: [string, Document[]]) => (
      <Fragment key={month}>
        <Chip label={month} sx={{ width: "fit-content" }} />
        {docs.map((doc: Document, i: number, arr: Document[]) => {
          const uri = `/documents/${doc.filename}`;

          return (
            <Stack spacing={3} key={doc.key}>
              <Stack
                direction={{ sm: "column", md: "row" }}
                justifyContent={"space-between"}
                gap={3}
              >
                <Typography variant="h6" sx={{ fontSize: "18px" }}>
                  {doc.label}
                </Typography>
                <Button
                  sx={{ minWidth: 150 }}
                  onClick={() => handleOpenDocument(uri, doc.key)}
                  variant="outlined"
                  disabled={loadingDocument === doc.key}
                >
                  {loadingDocument === doc.key ? (
                    <CircularProgress size={24} />
                  ) : (
                    "View Document"
                  )}
                </Button>
              </Stack>
              <Typography variant="subtitle2" color="text.secondary">
                {formatDate(doc.updated)}
              </Typography>
              {arr.length > 1 && <Divider />}
            </Stack>
          );
        })}
      </Fragment>
    ),
  );

  const noDocumentFound = (
    <Stack alignItems={"center"} spacing={5}>
      <img
        src="/assets/page-not-found.svg"
        alt="page not found"
        style={{ zIndex: 1, width: 133, height: 128, marginTop: "10%" }}
      />
      <Typography>No Documents Found</Typography>
    </Stack>
  );

  const renderDocuments = () => {
    if (error) {
      return "Something went wrong getting documents";
    }

    if (loadingDocuments) {
      return (
        <>
          <Skeleton sx={{ transform: "scale(1)" }} height={125} />
          <Skeleton sx={{ transform: "scale(1)" }} height={125} />
          <Skeleton sx={{ transform: "scale(1)" }} height={125} />
        </>
      );
    }

    if (documentLinks && documentLinks.length === 0) {
      return noDocumentFound;
    } else {
      return (
        <Card>
          <Stack p={3} pt={4} gap={4}>
            {documentLinks}
          </Stack>
        </Card>
      );
    }
  };

  return (
    <Container sx={{ py: 3 }} data-cy="document-library">
      <Stack spacing={3}>
        <CoolOffBanner
          profileData={profileData}
          kycStatus={kycData?.kycStatus as KYC_STATUS}
        />
        <Stack spacing={4}>
          <Stack spacing={1}>
            <Typography variant="h4">Document library</Typography>
            <Typography variant="body2">
              The current set of public documents relating to admittance and
              trading on the Archax exchange can be found below.
            </Typography>
          </Stack>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={currentTab}
              onChange={(e, value) => {
                setTab(value);
              }}
              aria-label="basic tabs example"
            >
              <Tab label="Bulletins" />
              <Tab label="Policies" />
            </Tabs>
          </Box>
          <TextField
            sx={{
              alignSelf: "end",
              maxWidth: "100%",
              width: 400,
            }}
            placeholder="Search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            variant="outlined"
            data-cy="document-search-input"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          {renderDocuments()}
        </Stack>
      </Stack>
    </Container>
  );
};
