// Handles most set- messages, data is same shape.

import {
  Execution,
  MarketDepth,
  Order,
  TradeHistoryEntry,
  Transaction,
} from "./types/ace-types";

// Understand - all these mutate the dataStates.
const regularMerge = (
  data: { [key: number]: any },
  dataState: { [key: string]: any },
  dataTopic: string,
) => {
  Object.keys(data).forEach(
    (entryId) =>
      (dataState[dataTopic][entryId] = {
        ...dataState[dataTopic][entryId],
        ...data[Number(entryId)],
      }),
  );

  return dataState;
};

type TransactionData = {
  entries: { [key: number]: Transaction };
};

const mergeExecutionsOnOrder = (
  executions?: { [key: string]: Execution },
  newExecutions?: { [key: string]: Execution },
) => {
  if (!executions) {
    return newExecutions;
  }

  if (!newExecutions) {
    return executions;
  }

  Object.keys(newExecutions).forEach((execId) => {
    executions[execId] = {
      ...executions[execId],
      ...newExecutions[execId],
    };
  });

  return executions;
};

const mergeOrders = (
  data: { [key: string]: Order },
  dataState: { [key: string]: any },
  dataTopic: string,
) => {
  Object.keys(data).forEach(
    (entryId) =>
      (dataState[dataTopic][entryId] = {
        ...dataState[dataTopic][entryId],
        ...data[Number(entryId)],
        executions: mergeExecutionsOnOrder(
          dataState[dataTopic][entryId]?.executions,
          data[Number(entryId)]?.executions,
        ),
      }),
  );

  return dataState;
};

// Same as above but set-transaction has a shape descrepancy with every other "set-" message i.e. it's nested in "entries".
const transactionsMerge = (
  data: TransactionData,
  dataState: { [key: string]: any },
  dataTopic: string,
) => {
  if (!dataState[dataTopic]) {
    dataState[dataTopic] = { entries: {} };
  }

  Object.keys(data.entries).forEach((entryId) => {
    const transactionId = data.entries[Number(entryId)].id;
    dataState[dataTopic].entries[transactionId] = data.entries[Number(entryId)];
  });

  return dataState;
};

// Trade Histories are managed as an array, which is limited to the last 20 trades.
const tradeHistoriesMerge = (
  data: { [key: number]: any },
  dataState: { [key: string]: any },
  dataTopic: string,
) => {
  Object.keys(data).forEach((entryId) => {
    const trades: TradeHistoryEntry[] = dataState[dataTopic][entryId];
    data[Number(entryId)].forEach((trade: TradeHistoryEntry) => {
      const index = trades.findIndex((t) => t.tradeRef === trade.tradeRef);
      if (index !== -1) {
        trades[index] = trade;
      } else {
        trades.push(trade);
      }
    });

    dataState[dataTopic][entryId] = trades
      .sort((a, b) => Number(b.created) - Number(a.created))
      .slice(0, 20);
  });

  return dataState;
};

export const mergeMarketDepths = (
  data: { [key: string]: MarketDepth },
  dataState: { [key: string]: any },
  topic: string,
) => {
  for (const [orderbookKey, newOrderbookUpdates] of Object.entries(data)) {
    if (newOrderbookUpdates.buy) {
      const newBuyOrderbook = {
        ...dataState[topic][orderbookKey].buy,
        ...newOrderbookUpdates.buy,
      };
      for (const key of Object.keys(newBuyOrderbook)) {
        if (newBuyOrderbook[key] <= 0) {
          delete newBuyOrderbook[key];
        }
      }
      dataState[topic][orderbookKey].buy = newBuyOrderbook;
    }
    if (newOrderbookUpdates.sell) {
      const newSellOrderbook = {
        ...dataState[topic][orderbookKey].sell,
        ...newOrderbookUpdates.sell,
      };
      for (const key of Object.keys(newSellOrderbook)) {
        if (newSellOrderbook[key] <= 0) {
          delete newSellOrderbook[key];
        }
      }

      dataState[topic][orderbookKey].sell = newSellOrderbook;
    }
    if (newOrderbookUpdates.indicativePrice) {
      dataState[topic][orderbookKey].indicativePrice =
        newOrderbookUpdates.indicativePrice;
    }
    if (newOrderbookUpdates.indicativeVolume) {
      dataState[topic][orderbookKey].indicativeVolume =
        newOrderbookUpdates.indicativeVolume;
    }
  }

  return dataState;
};

const updateOrInsertDataByTopic = (
  dataState: { [key: string]: any },
  dataTopic: string,
  data: { [key: number]: any },
) => {
  // transactions needs to come first cause of it's odd shape
  if (dataTopic === "transactions") {
    return transactionsMerge(data as TransactionData, dataState, dataTopic);
  }

  if (!dataState[dataTopic]) {
    dataState[dataTopic] = data;
    return dataState;
  }

  if (dataTopic.includes("orders")) {
    return mergeOrders(data, dataState, dataTopic);
  }

  if (dataTopic.includes("market-depths")) {
    return mergeMarketDepths(data, dataState, dataTopic);
  }

  if (dataTopic.includes("trade-histories")) {
    return tradeHistoriesMerge(data, dataState, dataTopic);
  }

  return regularMerge(data, dataState, dataTopic);
};

export { updateOrInsertDataByTopic };
