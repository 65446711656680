import { SvgIcon, SvgIconProps } from "@mui/material";

export const PageBlocked = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width="133"
      height="128"
      viewBox="0 0 133 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5" clipPath="url(#clip0_1295_118406)">
        <path
          d="M63.2095 128C97.4979 128 125.294 100.246 125.294 66.0111C125.294 31.7757 97.4979 4.02246 63.2095 4.02246C28.9212 4.02246 1.125 31.7757 1.125 66.0111C1.125 100.246 28.9212 128 63.2095 128Z"
          fill="url(#paint0_linear_1295_118406)"
        />
        <path
          d="M96.7038 18.4473H27.9272C23.6403 18.4473 20.165 21.9171 20.165 26.1975H104.466C104.466 21.9171 100.991 18.4473 96.7038 18.4473Z"
          fill="url(#paint1_linear_1295_118406)"
        />
        <path
          d="M20.165 25.9854H104.466V90.0911C104.466 92.9413 103.332 95.6748 101.314 97.6902C99.295 99.7056 96.5573 100.838 93.7027 100.838H30.9283C28.0737 100.838 25.336 99.7056 23.3175 97.6902C21.299 95.6748 20.165 92.9413 20.165 90.0911V25.9854Z"
          fill="url(#paint2_linear_1295_118406)"
        />
        <path
          d="M89.9754 87.0928H34.6833C33.5319 87.0928 32.5984 88.0238 32.5984 89.1723C32.5984 90.3207 33.5319 91.2517 34.6833 91.2517H89.9754C91.1269 91.2517 92.0604 90.3207 92.0604 89.1723C92.0604 88.0238 91.1269 87.0928 89.9754 87.0928Z"
          fill="url(#paint3_linear_1295_118406)"
        />
        <path
          d="M89.9752 69.4229H34.6831C33.5316 69.4229 32.5981 70.3539 32.5981 71.5023C32.5981 72.6508 33.5316 73.5818 34.6831 73.5818H89.9752C91.1266 73.5818 92.0601 72.6508 92.0601 71.5023C92.0601 70.3539 91.1266 69.4229 89.9752 69.4229Z"
          fill="url(#paint4_linear_1295_118406)"
        />
        <path
          d="M34.3086 65.6328H4.86648C2.50119 65.6328 0.58374 67.5473 0.58374 69.9089V111.927C0.58374 114.288 2.50119 116.203 4.86648 116.203H34.3086C36.6739 116.203 38.5914 114.288 38.5914 111.927V69.9089C38.5914 67.5473 36.6739 65.6328 34.3086 65.6328Z"
          fill="url(#paint5_linear_1295_118406)"
        />
        <path
          d="M31.0363 106.278H8.36151C7.59797 106.278 6.979 106.882 6.979 107.627V107.632C6.979 108.377 7.59797 108.981 8.36151 108.981H31.0363C31.7999 108.981 32.4188 108.377 32.4188 107.632V107.627C32.4188 106.882 31.7999 106.278 31.0363 106.278Z"
          fill="url(#paint6_linear_1295_118406)"
        />
        <path
          d="M31.0363 96.0107H8.36151C7.59797 96.0107 6.979 96.6147 6.979 97.3597V97.364C6.979 98.109 7.59797 98.7129 8.36151 98.7129H31.0363C31.7999 98.7129 32.4188 98.109 32.4188 97.364V97.3597C32.4188 96.6147 31.7999 96.0107 31.0363 96.0107Z"
          fill="url(#paint7_linear_1295_118406)"
        />
        <path
          d="M104.876 88.6403L96.8872 97.4346L119.407 117.83L127.396 109.035L104.876 88.6403Z"
          fill="url(#paint8_linear_1295_118406)"
        />
        <path
          d="M97.5481 115.328C98.8546 114.997 100.03 116.204 99.6635 117.501L98.9582 119.999C98.5919 121.296 96.9588 121.71 96.0186 120.744L94.2085 118.885C93.2683 117.919 93.7262 116.297 95.0327 115.966L97.5481 115.328Z"
          fill="url(#paint9_linear_1295_118406)"
        />
        <path
          d="M119.405 117.831L127.394 109.037L128.598 110.127C129.765 111.184 130.464 112.661 130.541 114.233C130.618 115.805 130.066 117.343 129.007 118.509C127.948 119.675 126.468 120.373 124.894 120.449C123.319 120.526 121.779 119.975 120.611 118.918L119.408 117.828L119.405 117.831Z"
          fill="#6C737F"
        />
        <path
          d="M31.0234 86.6523H8.15205C7.3819 86.6523 6.75757 87.2757 6.75757 88.0447V88.0492C6.75757 88.8181 7.3819 89.4415 8.15205 89.4415H31.0234C31.7936 89.4415 32.4179 88.8181 32.4179 88.0492V88.0447C32.4179 87.2757 31.7936 86.6523 31.0234 86.6523Z"
          fill="url(#paint10_linear_1295_118406)"
        />
        <path
          d="M16.5144 72.1211H9.83081C8.35032 72.1211 7.15015 73.3194 7.15015 74.7976V76.6496C7.15015 78.1278 8.35032 79.3261 9.83081 79.3261H16.5144C17.9949 79.3261 19.1951 78.1278 19.1951 76.6496V74.7976C19.1951 73.3194 17.9949 72.1211 16.5144 72.1211Z"
          fill="url(#paint11_linear_1295_118406)"
        />
        <path
          d="M89.9752 51.7529H34.6831C33.5316 51.7529 32.5981 52.6839 32.5981 53.8324C32.5981 54.9809 33.5316 55.9119 34.6831 55.9119H89.9752C91.1266 55.9119 92.0601 54.9809 92.0601 53.8324C92.0601 52.6839 91.1266 51.7529 89.9752 51.7529Z"
          fill="url(#paint12_linear_1295_118406)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M113.32 74.0848C113.32 92.4827 98.3823 107.397 79.9559 107.397C61.5296 107.397 46.592 92.4827 46.592 74.0848C46.592 55.6869 61.5296 40.7725 79.9559 40.7725C98.3823 40.7725 113.32 55.6869 113.32 74.0848ZM108.202 74.0848C108.202 89.6606 95.5557 102.287 79.9558 102.287C64.3558 102.287 51.7095 89.6606 51.7095 74.0848C51.7095 58.5089 64.3558 45.8822 79.9558 45.8822C95.5557 45.8822 108.202 58.5089 108.202 74.0848Z"
          fill="url(#paint13_linear_1295_118406)"
        />
        <path
          d="M57.5512 112.073C58.0451 110.027 60.9549 110.027 61.4488 112.073C61.6252 112.804 62.196 113.375 62.9271 113.551C64.9728 114.045 64.9728 116.955 62.9271 117.449C62.196 117.625 61.6252 118.196 61.4488 118.927C60.9549 120.973 58.0451 120.973 57.5512 118.927C57.3748 118.196 56.804 117.625 56.0729 117.449C54.0272 116.955 54.0272 114.045 56.0729 113.551C56.804 113.375 57.3748 112.804 57.5512 112.073Z"
          fill="url(#paint14_linear_1295_118406)"
        />
        <g filter="url(#filter0_b_1295_118406)">
          <path
            d="M79.956 102.287C95.5559 102.287 108.202 89.6603 108.202 74.0844C108.202 58.5086 95.5559 45.8818 79.956 45.8818C64.356 45.8818 51.7097 58.5086 51.7097 74.0844C51.7097 89.6603 64.356 102.287 79.956 102.287Z"
            fill="url(#paint15_linear_1295_118406)"
            fillOpacity="0.2"
          />
        </g>
        <path
          d="M86.7432 74.0844L94.8213 66.0008C95.5781 65.2011 95.9927 64.1383 95.9771 63.0382C95.9615 61.938 95.5169 60.8874 94.7377 60.1094C93.9585 59.3314 92.9062 58.8875 91.8044 58.8719C90.7026 58.8563 89.6381 59.2703 88.8372 60.0259L80.7411 68.1096L72.663 60.0259C72.2742 59.6152 71.807 59.2864 71.2888 59.0591C70.7706 58.8318 70.212 58.7106 69.6461 58.7026C69.0802 58.6946 68.5184 58.8 67.9939 59.0125C67.4695 59.2251 66.9931 59.5405 66.5929 59.9401C66.1927 60.3397 65.8768 60.8153 65.6639 61.339C65.451 61.8626 65.3454 62.4235 65.3535 62.9886C65.3615 63.5536 65.4829 64.1114 65.7106 64.6287C65.9382 65.1461 66.2675 65.6127 66.6789 66.0008L74.7705 74.0844L66.6789 82.1681C66.2675 82.5562 65.9382 83.0227 65.7106 83.5401C65.4829 84.0575 65.3615 84.6152 65.3535 85.1803C65.3454 85.7453 65.451 86.3063 65.6639 86.8299C65.8768 87.3535 66.1927 87.8292 66.5929 88.2288C66.9931 88.6284 67.4695 88.9438 67.9939 89.1563C68.5184 89.3689 69.0802 89.4743 69.6461 89.4663C70.212 89.4583 70.7706 89.337 71.2888 89.1097C71.807 88.8824 72.2742 88.5537 72.663 88.1429L80.7591 80.0593L88.8552 88.1429C89.6638 88.854 90.7134 89.2304 91.7903 89.1956C92.8672 89.1607 93.8902 88.7172 94.651 87.9553C95.4117 87.1935 95.8528 86.1707 95.8845 85.0954C95.9161 84.0201 95.5359 82.9732 94.8213 82.1681L86.7432 74.0844Z"
          fill="#4D5761"
        />
        <g filter="url(#filter1_f_1295_118406)">
          <ellipse
            cx="80.5"
            cy="89"
            rx="19.5"
            ry="3"
            fill="#1F2A37"
            fillOpacity="0.56"
          />
        </g>
        <path
          d="M120.327 59.4463C119.994 58.1402 121.2 56.9635 122.498 57.3281L124.996 58.0302C126.294 58.3948 126.71 60.0273 125.745 60.9687L123.888 62.7813C122.924 63.7227 121.302 63.2669 120.969 61.9609L120.327 59.4463Z"
          fill="url(#paint16_linear_1295_118406)"
        />
        <path
          d="M7.54814 50.3282C8.8546 49.9969 10.0298 51.2041 9.66348 52.5012L8.95819 54.9986C8.59188 56.2957 6.9588 56.7098 6.01865 55.744L4.20849 53.8845C3.26833 52.9187 3.72622 51.2974 5.03268 50.9661L7.54814 50.3282Z"
          fill="url(#paint17_linear_1295_118406)"
        />
        <path
          d="M89.9752 34.083H34.6831C33.5316 34.083 32.5981 35.014 32.5981 36.1625C32.5981 37.311 33.5316 38.242 34.6831 38.242H89.9752C91.1266 38.242 92.0601 37.311 92.0601 36.1625C92.0601 35.014 91.1266 34.083 89.9752 34.083Z"
          fill="url(#paint18_linear_1295_118406)"
        />
        <mask id="path-23-inside-1_1295_118406" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M123.872 33.547C124.917 33.547 125.764 32.6998 125.764 31.6546C125.764 30.6094 124.917 29.7621 123.872 29.7621C122.826 29.7621 121.979 30.6094 121.979 31.6546C121.979 32.6998 122.826 33.547 123.872 33.547ZM123.872 34.9664C125.701 34.9664 127.183 33.4836 127.183 31.6546C127.183 29.8255 125.701 28.3428 123.872 28.3428C122.043 28.3428 120.56 29.8255 120.56 31.6546C120.56 33.4836 122.043 34.9664 123.872 34.9664Z"
          />
        </mask>
        <path
          d="M123.138 31.6546C123.138 31.2493 123.466 30.9207 123.872 30.9207V36.1733C126.367 36.1733 128.39 34.1502 128.39 31.6546H123.138ZM123.872 32.3884C123.466 32.3884 123.138 32.0599 123.138 31.6546H128.39C128.39 29.1589 126.367 27.1358 123.872 27.1358V32.3884ZM124.605 31.6546C124.605 32.0599 124.277 32.3884 123.872 32.3884V27.1358C121.376 27.1358 119.353 29.1589 119.353 31.6546H124.605ZM123.872 30.9207C124.277 30.9207 124.605 31.2493 124.605 31.6546H119.353C119.353 34.1502 121.376 36.1733 123.872 36.1733V30.9207ZM124.557 31.6546C124.557 32.0332 124.25 32.3401 123.872 32.3401V37.5927C127.151 37.5927 129.81 34.9341 129.81 31.6546H124.557ZM123.872 30.9691C124.25 30.9691 124.557 31.276 124.557 31.6546H129.81C129.81 28.3751 127.151 25.7165 123.872 25.7165V30.9691ZM123.186 31.6546C123.186 31.276 123.493 30.9691 123.872 30.9691V25.7165C120.592 25.7165 117.934 28.3751 117.934 31.6546H123.186ZM123.872 32.3401C123.493 32.3401 123.186 32.0332 123.186 31.6546H117.934C117.934 34.9341 120.592 37.5927 123.872 37.5927V32.3401Z"
          fill="#6C737F"
          mask="url(#path-23-inside-1_1295_118406)"
        />
        <g filter="url(#filter2_d_1295_118406)">
          <path
            d="M118.121 0H85.601C84.0856 0 82.8572 1.22656 82.8572 2.7396V18.0012C82.8572 19.5142 84.0856 20.7408 85.601 20.7408H118.121C119.636 20.7408 120.865 19.5142 120.865 18.0012V2.7396C120.865 1.22656 119.636 0 118.121 0Z"
            fill="url(#paint19_linear_1295_118406)"
          />
        </g>
        <path
          d="M113.43 13.518C115.172 13.518 116.584 12.1079 116.584 10.3684C116.584 8.62889 115.172 7.21875 113.43 7.21875C111.687 7.21875 110.275 8.62889 110.275 10.3684C110.275 12.1079 111.687 13.518 113.43 13.518Z"
          fill="#6C737F"
        />
        <path
          d="M102.156 13.518C103.899 13.518 105.311 12.1079 105.311 10.3684C105.311 8.62889 103.899 7.21875 102.156 7.21875C100.414 7.21875 99.002 8.62889 99.002 10.3684C99.002 12.1079 100.414 13.518 102.156 13.518Z"
          fill="#6C737F"
        />
        <path
          d="M90.8833 13.518C92.6255 13.518 94.0378 12.1079 94.0378 10.3684C94.0378 8.62889 92.6255 7.21875 90.8833 7.21875C89.1411 7.21875 87.7288 8.62889 87.7288 10.3684C87.7288 12.1079 89.1411 13.518 90.8833 13.518Z"
          fill="#6C737F"
        />
        <path
          d="M12.5512 9.07291C13.0451 7.02716 15.9549 7.02716 16.4488 9.07291C16.6252 9.80397 17.196 10.3748 17.9271 10.5512C19.9728 11.0451 19.9728 13.9549 17.9271 14.4488C17.196 14.6252 16.6252 15.196 16.4488 15.9271C15.9549 17.9728 13.0451 17.9728 12.5512 15.9271C12.3748 15.196 11.804 14.6252 11.0729 14.4488C9.02716 13.9549 9.02716 11.0451 11.0729 10.5512C11.804 10.3748 12.3748 9.80397 12.5512 9.07291Z"
          fill="url(#paint20_linear_1295_118406)"
        />
        <mask id="path-30-inside-2_1295_118406" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M60.6407 5.98454C61.6858 5.98454 62.5331 5.13726 62.5331 4.09208C62.5331 3.0469 61.6858 2.19962 60.6407 2.19962C59.5955 2.19962 58.7482 3.0469 58.7482 4.09208C58.7482 5.13726 59.5955 5.98454 60.6407 5.98454ZM60.6407 7.40389C62.4697 7.40389 63.9525 5.92114 63.9525 4.09208C63.9525 2.26302 62.4697 0.780273 60.6407 0.780273C58.8116 0.780273 57.3289 2.26302 57.3289 4.09208C57.3289 5.92114 58.8116 7.40389 60.6407 7.40389Z"
          />
        </mask>
        <path
          d="M59.9068 4.09208C59.9068 3.6868 60.2354 3.35825 60.6407 3.35825V8.61084C63.1363 8.61084 65.1594 6.58772 65.1594 4.09208H59.9068ZM60.6407 4.82592C60.2354 4.82592 59.9068 4.49737 59.9068 4.09208H65.1594C65.1594 1.59644 63.1363 -0.426677 60.6407 -0.426677V4.82592ZM61.3745 4.09208C61.3745 4.49737 61.046 4.82592 60.6407 4.82592V-0.426677C58.145 -0.426677 56.1219 1.59644 56.1219 4.09208H61.3745ZM60.6407 3.35825C61.046 3.35825 61.3745 3.6868 61.3745 4.09208H56.1219C56.1219 6.58772 58.145 8.61084 60.6407 8.61084V3.35825ZM61.3262 4.09208C61.3262 4.47068 61.0193 4.77759 60.6407 4.77759V10.0302C63.9202 10.0302 66.5788 7.37161 66.5788 4.09208H61.3262ZM60.6407 3.40657C61.0193 3.40657 61.3262 3.71348 61.3262 4.09208H66.5788C66.5788 0.812557 63.9202 -1.84602 60.6407 -1.84602V3.40657ZM59.9552 4.09208C59.9552 3.71348 60.2621 3.40657 60.6407 3.40657V-1.84602C57.3611 -1.84602 54.7026 0.812557 54.7026 4.09208H59.9552ZM60.6407 4.77759C60.2621 4.77759 59.9552 4.47068 59.9552 4.09208H54.7026C54.7026 7.37161 57.3611 10.0302 60.6407 10.0302V4.77759Z"
          fill="#6C737F"
          mask="url(#path-30-inside-2_1295_118406)"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_1295_118406"
          x="35.0346"
          y="29.2067"
          width="89.8427"
          height="89.7556"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="8.33757" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1295_118406"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1295_118406"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_f_1295_118406"
          x="40.1561"
          y="65.1561"
          width="80.6879"
          height="47.6879"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="10.422"
            result="effect1_foregroundBlur_1295_118406"
          />
        </filter>
        <filter
          id="filter2_d_1295_118406"
          x="75.8572"
          y="-9"
          width="64.0076"
          height="46.7412"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="6" dy="4" />
          <feGaussianBlur stdDeviation="6.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.104618 0 0 0 0 0.465612 0 0 0 0 0.545833 0 0 0 0.09 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1295_118406"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1295_118406"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1295_118406"
          x1="62.7309"
          y1="-16.1625"
          x2="63.9988"
          y2="201.532"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="#9DA4AE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1295_118406"
          x1="20.0696"
          y1="20.2359"
          x2="104.507"
          y2="26.199"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9DA4AE" />
          <stop offset="1" stopColor="#6C737F" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1295_118406"
          x1="17.6338"
          y1="82.1155"
          x2="139.42"
          y2="84.284"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#E5E7EB" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1295_118406"
          x1="30.0835"
          y1="91.0802"
          x2="95.5775"
          y2="91.0802"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6C737F" />
          <stop offset="1" stopColor="#E5E7EB" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1295_118406"
          x1="30.0833"
          y1="73.4103"
          x2="95.5773"
          y2="73.4103"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6C737F" />
          <stop offset="1" stopColor="#E5E7EB" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1295_118406"
          x1="-0.557466"
          y1="103.554"
          x2="54.3603"
          y2="104.207"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#E5E7EB" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1295_118406"
          x1="5.90305"
          y1="108.869"
          x2="33.9236"
          y2="108.869"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6C737F" />
          <stop offset="1" stopColor="#E5E7EB" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1295_118406"
          x1="5.90305"
          y1="98.6015"
          x2="33.9236"
          y2="98.6015"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6C737F" />
          <stop offset="1" stopColor="#E5E7EB" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1295_118406"
          x1="102.075"
          y1="102.151"
          x2="110.126"
          y2="93.307"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9DA4AE" />
          <stop offset="1" stopColor="#6C737F" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1295_118406"
          x1="95.3384"
          y1="112.602"
          x2="102.313"
          y2="119.86"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9DA4AE" />
          <stop offset="1" stopColor="#6C737F" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_1295_118406"
          x1="5.67229"
          y1="89.3265"
          x2="33.9357"
          y2="89.3265"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6C737F" />
          <stop offset="1" stopColor="#E5E7EB" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_1295_118406"
          x1="6.64072"
          y1="79.0289"
          x2="19.9075"
          y2="79.0289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6C737F" />
          <stop offset="1" stopColor="#E5E7EB" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_1295_118406"
          x1="30.0833"
          y1="55.7404"
          x2="95.5773"
          y2="55.7404"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6C737F" />
          <stop offset="1" stopColor="#E5E7EB" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_1295_118406"
          x1="46.5165"
          y1="56.1485"
          x2="113.683"
          y2="56.5852"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9DA4AE" />
          <stop offset="1" stopColor="#6C737F" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_1295_118406"
          x1="47.974"
          y1="109.308"
          x2="71.1251"
          y2="109.458"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9DA4AE" />
          <stop offset="1" stopColor="#6C737F" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_1295_118406"
          x1="79.956"
          y1="45.8818"
          x2="108.342"
          y2="119.655"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#D2D6DB" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_1295_118406"
          x1="117.604"
          y1="61.6595"
          x2="124.853"
          y2="54.6758"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9DA4AE" />
          <stop offset="1" stopColor="#6C737F" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_1295_118406"
          x1="5.33841"
          y1="47.6017"
          x2="12.3128"
          y2="54.8599"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9DA4AE" />
          <stop offset="1" stopColor="#6C737F" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_1295_118406"
          x1="30.0833"
          y1="38.0705"
          x2="95.5773"
          y2="38.0705"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6C737F" />
          <stop offset="1" stopColor="#E5E7EB" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_1295_118406"
          x1="81.716"
          y1="15.553"
          x2="136.595"
          y2="17.143"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#E5E7EB" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_1295_118406"
          x1="2.97395"
          y1="6.30808"
          x2="26.1251"
          y2="6.45839"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9DA4AE" />
          <stop offset="1" stopColor="#6C737F" />
        </linearGradient>
        <clipPath id="clip0_1295_118406">
          <rect width="133" height="128" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
