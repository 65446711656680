const loadGoogleAnalytics = () => {
  const tagScript = document.createElement("script");
  tagScript.async = true;
  tagScript.src = "https://www.googletagmanager.com/gtag/js?id=G-DY214PNMV7";
  document.head.appendChild(tagScript);

  const configScript = document.createElement("script");
  configScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-DY214PNMV7');
    `;
  document.head.appendChild(configScript);
};

export { loadGoogleAnalytics };
