import {
  useContext,
  useRef,
  createContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { debounce } from "lodash";
import { nanoid } from "nanoid";
import { updateOrInsertDataByTopic } from "./update-or-insert-topic";
import { parseJwt } from "./jwt";
import {
  AceConnectionStatus,
  AceIncomingSocketMessage,
  AceJwt,
} from "./types/ace-types";
import { AceWebsocket } from "./ace-websocket";

const allTopicsLoaded = (data: any): boolean => {
  if (Object.values(data).length === 0) {
    return false;
  }
  return Object.values(data).filter((item) => item === undefined).length < 1;
};

const flushDataBuffer = debounce(
  (
    messages: { current: { messageData: any; messageTopic: string }[] },
    dataState,
    updateDataState,
  ) => {
    let newDataState = dataState;
    messages.current.forEach(({ messageTopic, messageData }) => {
      newDataState = updateOrInsertDataByTopic(
        newDataState,
        messageTopic,
        messageData,
      );
    });

    messages.current = [];

    updateDataState({ ...newDataState });
  },
  250,
  { maxWait: 250 },
);

interface AceContext {
  aceWebSocket: WebSocket | null;
  dataState?: any;
  connectionStatus?: AceConnectionStatus;
  permissionsInfo?: AceJwt;
  currentToken?: string;
  sendMessage: (
    msg: any,
    callback?: (
      res: AceIncomingSocketMessage | null,
      err?: { message: string },
    ) => void,
  ) => void;
}

export const AceContext = createContext<AceContext>({
  aceWebSocket: null,
  sendMessage: () => {},
});

interface AceProviderProps {
  token: string;
  autoRenewalStorageKey: string;
  aceAuthWebSocketUrl: string;
  onError?: (error: string) => void;
  children: React.ReactNode;
}

/**
 * The context provider to be used with `useAce` - requires a JWT and the ace-auth url (should be passed in the applications .env)
 *
 * @param {string} props.token - The token retrieved from the ACE AUTH Rest endpoint.
 * @param {string=} props.autoRenewalStorageKey - Optional: if given a storage will key, the auto renewal of the token will also put the new token into localStorage.
 * @param {string} props.aceAuthWebSocketUrl - The url for the ace websocket, WIP should default to production.
 * @param {string} props.onError - A callback triggered when a failure occurs, connection or otherwise.
 *
 */

const AceProvider = (props: AceProviderProps) => {
  const [connectionStatus, setConnectionStatus] = useState<AceConnectionStatus>(
    AceConnectionStatus.INITIALISING,
  );

  const [data, setData] = useState<any>({});
  const [currentToken, setToken] = useState<string>(props.token);
  const [permissionsInfo, setPermissionsInfo] = useState<AceJwt>(
    parseJwt(props.token),
  );
  const socketRef = useRef<WebSocket | null>(null);
  const dataMessageBuffer = useRef<
    { messageData: any; messageTopic: string }[]
  >([]);

  const dataRef = useRef({});

  // Sync the ref with the state, state to trigger controlled re-renders, the ref is for performant updates.
  // within this provider
  const updateDataState = (newState: any) => {
    dataRef.current = newState;
    setData(dataRef.current);
  };

  const handleDataMessage = (messageTopic: string, messageData: any) => {
    dataMessageBuffer.current.push({ messageTopic, messageData });

    flushDataBuffer(dataMessageBuffer, dataRef.current, updateDataState);

    (window as any).getState = () => dataRef;
  };

  // This can be called multiple times if the connection gets re-established
  // in which case, we need to dump the state if any exists to avoid
  // a mismatch between client state and server state from missed ws messages.
  const onAuthenticated = (newSocket: WebSocket) => {
    socketRef.current = newSocket;
    dataMessageBuffer.current = [];
    console.debug("STATE CLEARED");
    updateDataState({ uuid: window.crypto.randomUUID() });
    flushDataBuffer.cancel();
  };

  useEffect(() => {
    if (currentToken) {
      setPermissionsInfo(parseJwt(currentToken));
    }
  }, [currentToken]);

  useEffect(() => {
    const { cleanUpAceSocket } = AceWebsocket({
      url: props.aceAuthWebSocketUrl,
      token: props.token,
      updateConnectionStatus: setConnectionStatus,
      updateToken: setToken,
      onData: handleDataMessage,
      onError: props.onError,
      onAuthenticated: onAuthenticated,
      autoRenewalStorageKey: props.autoRenewalStorageKey,
    });

    return () => {
      cleanUpAceSocket("unmounted");
      delete (window as any).getState;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendMessage = useCallback(
    (
      msg: any,
      callback?: (
        res: AceIncomingSocketMessage | null,
        err?: { message: string },
      ) => void,
    ) => {
      console.debug("Message sent", msg);
      if (
        !socketRef?.current ||
        socketRef?.current?.readyState !== socketRef?.current.OPEN
      ) {
        props.onError &&
          props.onError("Attempt to send message on unopen socket");
        return;
      }

      if (!callback) {
        socketRef.current.send(JSON.stringify({ ...msg }));
        return;
      }

      const userReference = nanoid();
      let timeoutHandle = 0;

      const responseHandler = (message: MessageEvent) => {
        if (message.data === "pong") return;

        const res = JSON.parse(message.data) as AceIncomingSocketMessage;

        // send it back to the caller, bin the listener.
        if (res.data.userReference === userReference) {
          callback(res);
          if (socketRef.current) {
            socketRef.current.removeEventListener("message", responseHandler);
          } else {
            console.debug("Socket was null trying remove listener");
          }
          if (timeoutHandle) {
            clearTimeout(timeoutHandle);
          }
        }
      };

      const messageWithUserReference = {
        ...msg,
        data: { ...msg.data, userReference },
      };

      // if we don't get a response in time, bin the listener.
      timeoutHandle = Number(
        setTimeout(() => {
          const error = "Timeout waiting for websocket message response!";
          console.debug(error);
          callback(null, {
            message: error,
          });
          if (socketRef.current) {
            socketRef.current.removeEventListener("message", responseHandler);
          } else {
            console.debug("Socket was null trying remove listener");
          }
        }, 5000),
      );

      socketRef?.current.addEventListener("message", responseHandler);
      socketRef?.current.send(JSON.stringify(messageWithUserReference));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [socketRef?.current],
  );

  return (
    <AceContext.Provider
      value={{
        aceWebSocket: socketRef?.current,
        dataState: data,
        connectionStatus: connectionStatus,
        currentToken: currentToken,
        permissionsInfo: permissionsInfo,
        sendMessage: sendMessage,
      }}
    >
      {props.children}
    </AceContext.Provider>
  );
};

/**
 *
 * This react hook is the one-stop-shop for anything relating to the ACE Websocket API.
 *
 * Subscribe to data and send actions i.e. orders, withdrawals, cancellation.
 *
 * Pass in "topics" specified in ACE API docs subscribe-<topic> actions. (https://app.archax.com/docs/api/#websocket-api)
 *
 * ```
 * const { data, dataReady, connectionStatus, error, currentToken, permissionsInfo, updateTopics, sendMessage } = useAce(["market-depths/1"]);
 * ```
 * `data` - the data requested via the `topics` array arg. If no topics are passed will be an empty object `{}`.
 *
 *
 * `dataReady` - returns true is all data request via the topics param have been recieved
 * (Warning this does not include subsequent topics via updateTopics(), you will need to check that data is loaded).
 *
 *
 * `connectionStatus` - indicator if socket is ready to send messages i.e. if sendMessage and updateTopics are safe to call.
 *
 *
 * `currentToken` - the JWT token - automatically renews periodically. Make sure to listen for changes to this to ensure you are using the latest one if, for example you are using this to make ACE REST calls.
 *
 *
 * `permissionsInfo` - the decoded JWT - contains orgs id and roles useful for filtering data and UI feature blocking.
 *
 *
 * `updateTopics` - pass in a new array of topics to get different data.
 *
 *
 * ```
 * // Ex. getting market quotes for trading pair of id 7
 * updateTopics(["market-quotes/7"])
 * ```
 *
 *
 * `sendMessage` - to send a direct message to the ACE Websocket API.
 *
 * ```
 * // Ex. submit a withdrawal request, takes a msg and a callback.
 * sendMessage({ action: "submit-withdrawal", ...data }, (res, err) => {
 *  if(err) {
 *    handleError(err)
 *  }
 *  doSomethingWithData(res)
 * })
 * ```
 *
 *
 * To be used with `<AceConnectionProvider />` at the root of your application.
 *
 */
const useAce = (props?: { topics?: Array<string> }) => {
  const {
    aceWebSocket,
    dataState,
    permissionsInfo,
    currentToken,
    connectionStatus,
    sendMessage,
  } = useContext(AceContext);

  const [currentTopics, setCurrentTopics] = useState<string[] | null>(
    props?.topics || null,
  );
  const [dataReady, setDataReady] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = {} as any;

  if (currentTopics) {
    currentTopics.forEach((topic) => {
      data[topic] = dataState[topic];
    });
  }

  useEffect(() => {
    setDataReady(
      allTopicsLoaded(data) &&
        connectionStatus === AceConnectionStatus.CONNECTED,
    );
  }, [data, connectionStatus]);

  const updateTopics = useCallback(
    ({ topics }: { topics: string[] }) => {
      if (topics && connectionStatus === AceConnectionStatus.CONNECTED) {
        const newTopicList = Array.from(
          new Set([...(currentTopics || []), ...topics]),
        );
        if (JSON.stringify(newTopicList) === JSON.stringify(currentTopics)) {
          return;
        }
        newTopicList.forEach((topic) => {
          // don't send subscribe if we already are.

          if (!currentTopics?.includes(topic)) {
            sendMessage({ action: `subscribe-${topic}` });
          }
        });
        setCurrentTopics(newTopicList);
      }

      // TODO: if topics is empty array we could treat that as an unsub (unsub doesn't exist on the backend yet).
    },
    [connectionStatus, sendMessage, currentTopics],
  );

  useEffect(() => {
    if (props?.topics && connectionStatus === AceConnectionStatus.CONNECTED) {
      props.topics.forEach((topic) => {
        sendMessage({ action: `subscribe-${topic}` });
      });
      setCurrentTopics(props.topics);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionStatus, aceWebSocket]);

  return {
    data,
    dataState,
    dataReady,
    connectionStatus,
    currentToken,
    permissionsInfo,
    updateTopics,
    sendMessage,
  };
};

export { AceProvider, useAce };
