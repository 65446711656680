import { FC, ReactNode, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Theme, styled } from "@mui/material/styles";
import { useMediaQuery } from "@mui/system";

import type { NavColor } from "../../../types/settings";

import type { Section } from "../config";
import { MobileNav } from "../mobile-nav";
import { SIDE_NAV_WIDTH, SIDE_NAV_WIDTH_CLOSED, SideNav } from "./side-nav";
import { useDrawer } from "../../../hooks/use-drawer";
import { MobileBlockerPage } from "../../../pages/mobile-blocker";
import { useLocation } from "react-router-dom";
import { useRiskBannerHeight } from "../../../hooks/ace/use-risk-banner-height";

interface VerticalLayoutRootProps {
  ownerState: {
    isSidebarOpen: boolean;
  };
}

const VerticalLayoutRoot = styled("div")<VerticalLayoutRootProps>(
  ({ theme, ownerState }) => ({
    display: "flex",
    flex: "1 1 auto",
    maxWidth: "100%",
    overflowX: "hidden",
    [theme.breakpoints.up("lg")]: {
      marginLeft: ownerState.isSidebarOpen
        ? SIDE_NAV_WIDTH
        : SIDE_NAV_WIDTH_CLOSED,
    },
  }),
);

const VerticalLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
});

interface VerticalLayoutProps {
  children?: ReactNode;
  navColor?: NavColor;
  sections?: Section[];
}

export const VerticalLayout: FC<VerticalLayoutProps> = (props) => {
  const { children, sections, navColor } = props;
  // this is blocking the ability to dev on smaller monitors.
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const mobileNav = useDrawer();
  const [desktopNavOpen, setDesktopNavOpen] = useState<boolean>(true);
  const { pathname } = useLocation();
  const riskBannerHeight = useRiskBannerHeight();

  const isAccountPage = useMemo(() => pathname.includes("account"), [pathname]);

  const showMobileBlocker = !isAccountPage && !mdUp;

  return (
    <>
      {mdUp && (
        <SideNav
          color={navColor}
          sections={sections}
          open={desktopNavOpen}
          setOpen={setDesktopNavOpen}
        />
      )}
      {!mdUp && (
        <MobileNav
          color={navColor}
          onOpen={mobileNav.handleOpen}
          onClose={mobileNav.handleClose}
          open={mobileNav.open}
          sections={sections}
        />
      )}
      <MobileBlockerPage show={showMobileBlocker} />
      <VerticalLayoutRoot
        sx={{
          marginTop: mdUp ? riskBannerHeight + "px" : null,
          display: !showMobileBlocker ? "block" : "none",
        }}
        ownerState={{ isSidebarOpen: desktopNavOpen }}
      >
        <VerticalLayoutContainer>{children}</VerticalLayoutContainer>
      </VerticalLayoutRoot>
    </>
  );
};

VerticalLayout.propTypes = {
  navColor: PropTypes.oneOf<NavColor>(["blend-in", "discrete", "evident"]),
  sections: PropTypes.array,
};
