import posthog from "posthog-js";

import { SourceMapConsumer } from "source-map-js";

// The are errors that are thrown because ChartIQ has problems being run in Cypress (automation browsers)
// We can use this for other noisy error messages.
const ERROR_MESSAGES_TO_FILTER = [
  "Cannot read properties of undefined (reading 'stx')",
  "Cannot read properties of null (reading 'keystrokeHub')",
];

interface loadPosthogParams {
  postHogKey: string;
  consentTimestamp?: string;
}

async function resolveSourceMap(stackLine: string): Promise<string> {
  const match = stackLine.match(/at .+ \((.+):(\d+):(\d+)\)/);
  if (!match) return stackLine;

  const [, scriptUrl, line, column] = match;
  const sourceMapUrl = `${scriptUrl}.map`;

  try {
    const response = await fetch(sourceMapUrl);
    const sourceMapData = await response.json();
    const consumer = await new SourceMapConsumer(sourceMapData);

    const position = consumer.originalPositionFor({
      line: parseInt(line, 10),
      column: parseInt(column, 10),
    });

    return `at ${position.source}:${position.line}:${position.column}`;
  } catch (error) {
    return stackLine; // Return original on failure
  }
}

const loadPosthog = ({ postHogKey, consentTimestamp }: loadPosthogParams) => {
  if (!postHogKey) {
    console.log("Posthog key not passed in...");
    return;
  }
  posthog.init(postHogKey, {
    api_host: "https://eu.i.posthog.com",
    autocapture: {
      dom_event_allowlist: ["click", "submit"],
    },
    loaded: () => {
      consentTimestamp &&
        posthog.capture("consent_timestamp", { consentTimestamp });
    },
    rageclick: true,
  });

  // only bother doing override and listeners if tracking is established.
  window.onerror = (a, b, c, d, e) => {
    const error = `message: ${a} source: ${b} lineno: ${c} colno: ${d} error: ${e}`;
    if (e && e.stack) {
      const stackLines = e.stack.split("\n");

      Promise.all(stackLines.map(resolveSourceMap))
        .then((resolvedStackLines) => {
          const resolvedStack = resolvedStackLines.join("\n");
          posthog.capture("error", { errorEvent: error, resolvedStack });
        })
        .catch(console.error); // Handle any errors in the promise chain
    } else {
      posthog.capture("error", { errorEvent: error });
    }
  };

  const originalConsoleError = console.error;

  // Override the console.error function
  console.error = (...args) => {
    const errorMessage = args.join(" ");

    const shouldFilter = ERROR_MESSAGES_TO_FILTER.some((filterMessage) =>
      errorMessage.includes(filterMessage),
    );

    if (!shouldFilter) {
      posthog.capture("console.error", { message: errorMessage });
    }

    // Call the original console.error function
    originalConsoleError(...args);
  };
};

export { loadPosthog };
